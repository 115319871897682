import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, catchError, map, of } from 'rxjs';
import { UserProfile } from '../models/user.model';
import { Router } from '@angular/router';
import { MsalService } from '@azure/msal-angular';
import { RolePriority, RoleMappings, Roles } from '../../common/constants';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  private readonly GRAPH_ENDPOINT = 'https://graph.microsoft.com/v1.0';

  constructor(private readonly http: HttpClient, 
              private router: Router,
              private msalService: MsalService) {}

  public getProfilePicture(): Observable<Blob> {
    return this.http
      .get('https://graph.microsoft.com/v1.0/me/photo/$value', {
        responseType: 'blob',
      })
  
  }

  public getProfile(): Observable<UserProfile> {
    return this.http.get(`${this.GRAPH_ENDPOINT}/me`).pipe(
      map((data: UserProfile) => data),
      catchError(() => {
        return of({});
      })
    );
  }

  public getUserRole(): string{
    const userRoles = this.msalService.instance.getAllAccounts()[0]?.idTokenClaims?.roles ?? [];
    const currentUserRole  = this.getHighestRole(userRoles);
    sessionStorage.setItem("currentUserRole", currentUserRole) ;
    return currentUserRole;
  }

  // public set setUserRole(roles: string[]) {
  //   const currentUserRole  = this.getHighestRole(roles);
  //   sessionStorage.setItem("currentUserRole", currentUserRole) ;
  // }

  // public get getUserRole(): string {
  //   const userRole = sessionStorage.getItem("currentUserRole");
  //   return userRole !== null ? userRole : "";
  // }

  public getHighestRole(roles: string[]): string {
    if (!roles || roles.length === 0) {
      this.router.navigate(['/no-access']);
      return '';
    }
    let highestRole = '';
    for (const role of roles) {
      const roleName = role.trim().trim().split('-').pop();
      if (roleName) {
        if (
          !highestRole || RolePriority[roleName] > RolePriority[highestRole.toLowerCase()]
        ) {
          highestRole = roleName;
        }
      }
    }
    return RoleMappings[highestRole as keyof typeof RoleMappings];
  }


  
  //Only Admin
  canEditIdentificationsGroup(): boolean {
    return (this.getUserRole() == Roles.ADMIN)
  }
  canEditDignosticFamilies(): boolean {
    return (this.getUserRole() == Roles.ADMIN)
  }
  canEditUsers(): boolean {
    return (this.getUserRole() == Roles.ADMIN)
  }
  canCreateExportLabel(): boolean {
    return (this.getUserRole() == Roles.ADMIN)
  }
  canManageTemplate(): boolean {
    return (this.getUserRole() == Roles.ADMIN)
  }
  canApproveRbacc(): boolean {
    return (this.getUserRole() == Roles.ADMIN)
  }

  //Only Admin or System Owner
  canReleaseVersion(): boolean {
    return (this.getUserRole() == Roles.ADMIN) || (this.getUserRole() == Roles.SYSTEMOWNER)
  }
  canCreateDeleteSpecification(): boolean {
    return (this.getUserRole() == Roles.ADMIN) || (this.getUserRole() == Roles.SYSTEMOWNER)
  }
  canEditSharedFiles(): boolean {
    return (this.getUserRole() == Roles.ADMIN) || (this.getUserRole() == Roles.SYSTEMOWNER)
  }
  canEditRbacc(): boolean {
    return (this.getUserRole() == Roles.ADMIN) || (this.getUserRole() == Roles.SYSTEMOWNER)
  }
  canEditSCOMMFiles(): boolean {
    return (this.getUserRole() == Roles.ADMIN) || (this.getUserRole() == Roles.SYSTEMOWNER)
  }
  canEditServer(): boolean {
    return (this.getUserRole() == Roles.ADMIN) || (this.getUserRole() == Roles.SYSTEMOWNER)
  }
  canEditDiagnosticService(): boolean {
    return (this.getUserRole() == Roles.ADMIN) || (this.getUserRole() == Roles.SYSTEMOWNER)
  }
  canEditLegacyFile():boolean{
    return (this.getUserRole() == Roles.ADMIN) || (this.getUserRole() == Roles.SYSTEMOWNER)
  }

  //Only Admin or System Owner or collaborator
  canEditCategoryItems(): boolean {
    return (this.getUserRole() == Roles.ADMIN) || (this.getUserRole() == Roles.SYSTEMOWNER) || (this.getUserRole() == Roles.COLLABORATOR)
  }
  CanEditSpecificationVersion(): boolean {
    return (this.getUserRole() == Roles.ADMIN) || (this.getUserRole() == Roles.SYSTEMOWNER) || (this.getUserRole() == Roles.COLLABORATOR)
  }
  canAddVersions(): boolean {
    return (this.getUserRole() == Roles.ADMIN) || (this.getUserRole() == Roles.SYSTEMOWNER) || (this.getUserRole() == Roles.COLLABORATOR)
  }
  canValidateServerFile(): boolean {
    return (this.getUserRole() == Roles.ADMIN) || (this.getUserRole() == Roles.SYSTEMOWNER) || (this.getUserRole() == Roles.COLLABORATOR)
  }

  //Only Admin or System Owner or collaborator or reader
  canEditExportPackage(): boolean {
    return (this.getUserRole() == Roles.ADMIN || this.getUserRole() == Roles.SYSTEMOWNER || this.getUserRole() == Roles.COLLABORATOR || this.getUserRole() == Roles.READER)
  }
  canExportByLabel(): boolean {
    return (this.getUserRole() == Roles.ADMIN || this.getUserRole() == Roles.SYSTEMOWNER || this.getUserRole() == Roles.COLLABORATOR || this.getUserRole() == Roles.READER)
  }
}
